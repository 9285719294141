import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';
import { DateTime } from 'luxon';
import SecretsHeader from '../components/SecretsHeader';
import Secret from '../components/Secret';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../components/Loader';
import firebase from 'firebase/app';
import 'firebase/firestore';
import socialCard from '../images/social_card.jpg';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyBO7tDI-xToGi2kEwC9aGthEOwlFU1c348",
    authDomain: "ai-ball-ad20f.firebaseapp.com",
    projectId: "ai-ball-ad20f"
  });
}

const db = firebase.firestore();

function Secrets() {
  const [secrets, setSecrets] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);

  useEffect(() => {
    db.collection("counters").doc("answers").get().then(doc => {
      if (doc.exists) {
        setTotalCount(doc.data().count);
      } else {
        setTotalCount(0)
      }
    });

    db.collection("secrets")
      .orderBy("timestamp", "desc")
      .limit(25)
      .get()
      .then(documentSnapshots => {
        setSecrets(documentSnapshots.docs);
        setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
      });
  }, []);

  const loadMore = page => {
    if (lastVisible) {
      db.collection("secrets")
        .orderBy("timestamp", "desc")
        .startAfter(lastVisible)
        .limit(25)
        .get()
        .then(documentSnapshots => {
          let updatedSecrets = [...secrets];
          updatedSecrets = updatedSecrets.concat(documentSnapshots.docs);

          setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
          setSecrets(updatedSecrets);
        });
    }
  }

  return (
    <>
      <main className="secrets">
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/bsy8vcq.css"/>
          <title>The Secrets of the Universe</title>

          <meta property="og:title" content="Ask the AI Ball" />
          <meta property="og:url" content="https://asktheaiball.com" />
          <meta property="og:image" content={socialCard} />

          {/* Google Tag Manager */}
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5949DKN');
            `}
          </script>
          {/* End Google Tag Manager */}
        </Helmet>

        <SecretsHeader
          total={totalCount}
        />

        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={secrets.length < totalCount}
          loader={<Loader key={0}/>}
          threshold={100}
        >
          {secrets.map(doc =>
            <React.Fragment key={doc.id}>
              {!doc.data().answer.toLowerCase().match(/talk to someone/) ?
                <Secret>
                  <h3>
                    {DateTime.fromISO(doc.data().timestamp).toFormat("LLL dd yyyy h:mm ")}
                    <span>
                      {DateTime.fromISO(doc.data().timestamp).toFormat("a")}
                    </span>
                  </h3>
                  <h2>{doc.data().query}</h2>
                  <p
                    aria-label={doc.data().description}
                  >
                    {doc.data().answer}
                  </p>
                </Secret>
              : null}
            </React.Fragment>
          )}
        </InfiniteScroll>
      </main>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5949DKN"
          title="GTM"
          height="0"
          width="0"
          style={{
            display: 'none',
            visibility: 'hidden'
          }}
        />
      </noscript>
      {/* End Google Tag Manager (noscript) */}
    </>
  );
};

export default Secrets;