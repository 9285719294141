import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import BackBlue from '../images/back_blue.inline.svg';
import StarCluster from '../images/star_cluster.inline.svg';
import predictionsBg from '../images/background_predictions.svg';
import { blue } from '../colors';

const AnswerHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (min-width: 860px) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > div {
    width: 180px;
    flex: 0 0 auto;

    &:nth-child(1) {
      & svg {
        display: block;
        margin: 0 auto 28px;

        @media screen and (min-width: 860px) {
          margin: 0;
        }
      }
    }

    &:nth-child(2) {
      width: auto;
      text-align: center;
      margin-bottom: 48px;

      @media screen and (min-width: 860px) {
        margin-bottom: 0;
      }

      & svg {
        width: 106px;
        height: 45px;
        margin-bottom: 2px;
      }
    }

    &:nth-child(3) {
      & > div {
        margin: 0 auto;

        @media screen and (min-width: 860px) {
          margin-right: 0;
        }
      }
    }
  }

  & h1 {
    margin: 0 0 6px;
    color: ${blue};
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }

  & a {
    display: inline-block;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 1.75rem;
    letter-spacing: 0.04em;
    transition: opacity 0.21s ease-out;
    color: ${blue};
    text-decoration: none;
    border-bottom: 2px solid ${blue};

    &:hover,
    &:focus {
      opacity: 0.75;
      outline: none;
    }
  }

  & svg {
    width: 134px;
    height: 134px;
  }
`;

const Predictions = styled.div`
  width: 150px;
  height: 130px;
  background: url(${predictionsBg}) top center no-repeat;
  background-size: contain;

  & h4 {
    text-align: center;
    font-size: 48px;
    font-size: 3rem;
    color: ${blue};
    letter-spacing: 0.08em;
    margin: 0;

    & span {
      display: block;
      font-size: 22px;
      font-size: 1.375rem;
      text-transform: uppercase;
      letter-spacing: 0.12em;
      margin-top: -4px;
    }
  }
`;

const SecretsHeader = props => {
  const { total } = props;

  return (
    <>
      <AnswerHeader>
        <div>
          <BackBlue/>
        </div>

        <div>
          <StarCluster/>

          <h1>The Secrets of the Universe</h1>

          <Link to="/">
            Ask the AI Ball
          </Link>
        </div>

        <div>
          <Predictions>
            <h4>
              {total}
              <span>prediction{total === 1 ? '' : 's'}</span>
            </h4>
          </Predictions>
        </div>
      </AnswerHeader>
    </>
  );
}

export default SecretsHeader;