import React from 'react';
import styled from 'styled-components';
import hourglass from '../images/Blue_v1.gif';

const LoaderContainer = styled.div`
  padding: 12px 0;

  & img {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <img src={hourglass} alt=""/>
    </LoaderContainer>
  );
};

export default Loader;