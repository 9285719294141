import styled from 'styled-components';
import { blue, purple } from '../colors';

const Secret = styled.div`
  padding-top: 24px;
  padding-bottom: 38px;
  border-bottom: 2px solid ${purple};
  position: relative;

  @media screen and (min-width: 960px) {
    display: flex;
  }

  & h3 {
    display: block;
    width: 100px;
    font-size: 22px;
    font-size: 1.375rem;
    color: ${blue};
    text-transform: uppercase;
    line-height: 0.9;
    margin: 0;
    padding-top: 7px;
    padding-right: 52px;
    position: absolute;
    top: 24px;
    left: 0;

    @media screen and (min-width: 960px) {
      position: static;
    }

    & span {
      font-size: 10px;
      font-size: 0.625rem;
    }
  }

  & h2,
  & p {
    color: ${blue};
    margin: 0 0 0 80px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

    @media screen and (min-width: 500px) {
      margin-left: 100px;
    }

    @media screen and (min-width: 960px) {
      margin: 0;
      width: calc(50% - 50px);
      flex: 0 0 auto;
      padding-right: 32px;
    }
  }

  & h2 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.1;
    margin-bottom: 12px;

    @media screen and (min-width: 500px) {
      font-size: 40px;
      font-size: 2.5rem;
      margin-bottom: 20px;
    }

    @media screen and (min-width: 960px) {
      margin-bottom: 0;
    }
  }

  & p {
    font-size: 22px;
    font-size: 1.375rem;
    text-transform: uppercase;
    line-height: 0.93;
    letter-spacing: 0.15em;
    padding-top: 10px;

    @media screen and (min-width: 500px) {
      font-size: 30px;
      font-size: 1.875rem;
    }
  }
`;

export default Secret;